<template>
  <header class="navbar-component bg-water mb-4">
    <div>
      <div class="container">
        <div class="d-none d-lg-flex align-items-center py-3">
          <div class="flex-fill">
            <NuxtLink to="/">
              <img
                src="../assets/imgs/likewater_horizontal_white_1.png"
                alt="logo"
                class="img-fluid"
              />
            </NuxtLink>
          </div>
          <div class="d-flex align-items-center">
            <NuxtLink to="/">Home</NuxtLink>
            <el-dropdown @visible-change="showDrop = !showDrop" class="mx-5">
              <span class="el-dropdown-link d-flex align-items-center">
                Soluções
                <img
                  src="../assets/imgs/expand_left.png"
                  class="img-fluid"
                  :class="[!showDrop ? 'rotate' : '']"
                />
              </span>

              <template #dropdown>
                <el-dropdown-menu class="bg-water">
                  <el-dropdown-item>
                    <NuxtLink class="link" to="/espresso-lw"
                      >Espresso LW</NuxtLink
                    >
                  </el-dropdown-item>
                  <el-dropdown-item
                    ><NuxtLink class="link" to="/presenca-omnicanal"
                      >Presença Omnicanal</NuxtLink
                    ></el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <NuxtLink to="/sobre-nos">Sobre nós</NuxtLink>
            <NuxtLink class="mx-5" to="/planos-e-precos"
              >Planos e preços</NuxtLink
            >
            <a
              href="https://wa.me/551151948686"
              target="_blank"
              class="btn btn-sm btn-navBar hover"
              >Fale conosco</a
            >
          </div>
        </div>
        <div class="d-lg-none">
          <div class="d-flex justify-content-end py-3">
            <button class="btn" @click="showDrawer = true">
              <img
                src="../assets/imgs/menu-hamburguer.svg"
                width="24px"
                alt="Menu"
              />
            </button>
          </div>
          <el-drawer
            @click="showDrawer = false"
            style="background-color: #008e9f"
            v-model="showDrawer"
            size="70%"
            class="text-white px-2"
          >
            <template #header>
              <div class="ps-4">
                <NuxtLink to="/">
                  <img
                    src="../assets/imgs/likewater_horizontal_white_1.png"
                    alt="logo"
                    class="img-fluid"
                  />
                </NuxtLink>
              </div>
            </template>
            <template #default>
              <div class="d-flex flex-column">
                <NuxtLink to="/">Home</NuxtLink>

                <NuxtLink class="link py-4" to="/espresso-lw"
                  >Espresso LW</NuxtLink
                >

                <NuxtLink class="link" to="/presenca-omnicanal"
                  >Presença Omnicanal</NuxtLink
                >
                <NuxtLink class="py-4" to="/sobre-nos">Sobre nós</NuxtLink>
                <NuxtLink class="pb-4" to="/planos-e-precos"
                  >Planos e preços</NuxtLink
                >
              </div>
            </template>
            <template #footer>
              <a
                href="https://wa.me/551151948686"
                target="_blank"
                class="btn w-100 btn-sm btn-navBar hover"
                >Fale conosco</a
              >
            </template>
          </el-drawer>
        </div>
      </div>
    </div>
  </header>
</template>
<script setup>
const showDrop = ref(false);
const showDrawer = ref(false);
</script>
<style lang="scss" scoped>
:deep(.el-icon) {
  color: #fff !important;
}
.navbar-component {
  a,
  span {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
  }

  .btn-navBar {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 10px;
    background: var(--clay, #d8be9b);
  }

  .el-dropdown-menu__item {
    color: #fff;
    text-decoration: none;
  }

  .rotate {
    transform: rotate(180deg);
  }
}
.el-dropdown-menu__item:not(.is-disabled) {
  &:hover {
    background: transparent !important;
  }
  &:focus {
    background-color: transparent !important;
  }
}

:deep(.el-dropdown-menu__item:not(.is-disabled):focus) {
  background-color: transparent !important;
}
</style>
