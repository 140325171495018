<template>
  <div class="button-contact py-5">
    <div v-if="isOpen" class="ballon-content position-absolute">
      <div
        class="ballon-container text-white d-flex align-items-center pt-4 flex-column"
      >
        <p class="mb-3">Ainda tenho dúvidas!</p>
        <a
          href="https://wa.me/551151948686"
          target="_blank"
          class="btn-whatsapp hover d-flex align-items-center justify-content-center bg-water text-white"
          rel="noopener noreferrer"
        >
          <img
            src="../assets/imgs/whatsapp.svg"
            alt="Whatsapp"
            class="me-2"
          />Converse com a gente</a
        >
      </div>
      <div class="ballon-footer"></div>
    </div>
    <button
      @click="isOpen = !isOpen"
      class="btn btn-contact hover d-flex align-items-center justify-content-center"
    >
      <img v-if="!isOpen" src="../assets/imgs/message.svg" alt="Message" />
      <img v-else src="../assets/imgs/expand_bottom.svg" alt="Expand bottom" />
    </button>
  </div>
</template>
<script setup>
const isOpen = ref(false);
</script>
<style lang="scss" scoped>
.button-contact {
  position: fixed;
  bottom: 24px;
  z-index: 100;
  right: 32px;
  .btn-contact {
    background: #4e6160;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: all 1s;
    &:hover {
      transform: scale(1.3);
    }
  }
  .btn-whatsapp {
    border-radius: 10px;
    width: 201px;
    height: 36px;
  }

  .ballon-container {
    width: 259px;
    height: 124px;
    border-radius: 30px;
    background: #d8be9b;
    right: -14px;
    bottom: 20px;
    position: absolute;
  }
  .ballon-footer {
    position: absolute;
    right: -12px;
    bottom: 2px;
    width: 0;
    height: 0;
    border-left: 16.25px solid transparent;
    border-right: 16.25px solid transparent;
    border-bottom: 32.5px solid #d8be9b;
    transform: rotate(160deg);
  }
}
</style>
