<template>
  <footer class="footer-component bg-water">
    <div class="container">
      <div class="row text-white">
        <div class="col-md-4 d-flex flex-column">
          <div class="flex-fill text-md-start text-center">
            <img
              width="96"
              src="../assets/imgs/likewater_horizontal_white_2.png"
              alt="LogoLike"
              class="img-fluid mb-4"
            />
          </div>
          <div class="d-md-block d-none">
            <p class="mb-0">
              Todos os direitos reservados ©LikeWater Communication Solutions
            </p>
            <NuxtLink to="/politica-privacidade"
              >Politica de privacidade</NuxtLink
            >
          </div>
        </div>
        <div class="col-md-4 d-flex align-items-center flex-column">
          <div class="d-flex flex-column mb-md-0 mb-4">
            <NuxtLink to="/"> Home</NuxtLink>
            <NuxtLink class="my-1" to="/espresso-lw">Expresso LW</NuxtLink>
            <NuxtLink to="/presenca-omnicanal">Presença Omnicanal</NuxtLink>
            <NuxtLink class="my-1" to="/sobre-nos">Sobre nós </NuxtLink>
            <NuxtLink to="/planos-e-precos">Planos e preços</NuxtLink>
          </div>
          <div class="d-flex flex-column align-items-center mb-4 d-md-none">
            <p class="mb-0">
              Todos os direitos reservados ©LikeWater Communication Solutions
            </p>
            <NuxtLink to="/politica-privacidade"
              >Politica de privacidade</NuxtLink
            >
          </div>
        </div>
        <div class="col-md-4 d-flex flex-column">
          <div class="flex-fill">
            <p class="mb-0">Rua Cláudio Soares, 72 – Sala 1610, Pinheiros,</p>
            <p class="mb-0">05422-030, São Paulo, SP, Brasil</p>
          </div>

          <div class="d-flex flex-column pt-md-0 pt-2">
            <a
              class="mb-0 pb-0"
              href="https://wa.me/551151948686"
              target="_blank"
              rel="noopener noreferrer"
              ><img
                class="me-2"
                src="../assets/imgs/whatsapp.svg"
                alt="Whatsapp"
              />+55 11 5194-8686</a
            >
            <p class="mb-0 pb-0 mt-1">
              <img
                width="20px"
                src="../assets/imgs/telefone.png"
                class="me-2"
                alt=""
              />(11) 9261-3707
            </p>
             <a
              class="mt-n3"
              href="mailto:contato@likewatercs.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="../assets/imgs/email.svg" alt="Email" class="me-2" />
              contato@likewatercs.com
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<style lang="scss" scoped>
.footer-component {
  padding: 24px;
  max-height: 180px;
  p,
  a {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  a {
    text-decoration: none;
    transition: 1s filter;
    &:hover {
      filter: brightness(80%);
    }
  }
  @media screen and (max-width: 767px) {
    max-height: 100%;
    p,
    a {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }
  }
  @media screen and (max-width: 991px) {
    p,
    a {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }
  }
}
</style>
